@import "src/scss/variables.scss";
@import "src/scss/animations.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.mobile-search-header {
  background-color: $color-black-0;
}

.mobile-search-header__content {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr auto;
  padding: 38px 16px 26px;
}

.mobile-search-header__button {
  @include reset-button();
  align-items: center;
  color: $color-theme-primary;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.mobile-search-header__button_clear {
  animation: fadeIn ease-out forwards $transition-time;
  background-color: $color-primary-light-alpha;
  border-radius: 50%;
}

.mobile-search-header__input {
  @include textAccent();
  border: none;
  outline: none;
  padding: 0;
  width: 100%;

  &::placeholder {
    @include textAccent($color: $color-black-50);
  }
}
