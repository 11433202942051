@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/direction.scss";
@import "src/scss/mixins/tablet-footer.scss";

.footer {
  background-color: $color-black-0;
  border-top: 1px solid $color-borders-light;
  box-sizing: border-box;
  display: grid;
  gap: 32px;
  margin: 0 auto;
  max-width: 1044px;
  padding: 23px 16px 24px;
  width: 100%;
}

.footer_with-app-links {
  gap: 24px;

  .footer__nav {
    grid-row: 2;
  }

  .footer__links {
    padding-bottom: 8px;
  }
}

.footer__logo {
  display: grid;
  justify-content: start;
}

.footer__logo__img {
  height: 32px;
  width: 194px;
}

.footer__nav {
  display: grid;
  grid-row: 1;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
}

.footer__nav_many-social-links,
.footer_with-app-links .footer__nav {
  gap: 24px;
  grid-template-columns: none;

  .footer__social {
    grid-row: 1;
  }
}

.footer__links {
  align-items: center;
  display: grid;
  gap: 12px 24px;
  grid-template-columns: auto 1fr;

  .footer__link {
    @include ltr() {
      &:nth-of-type(1) {
        order: 1;
      }

      &:nth-of-type(2) {
        grid-column: 1 / 3;
        order: -1;
      }
    }

    @include rtl() {
      &:nth-of-type(1) {
        order: initial;
      }

      &:nth-of-type(2) {
        grid-column: initial;
        order: -1;
      }

      &:nth-of-type(3) {
        grid-column: 1 / 3;
      }
    }
  }
}

.footer__link {
  @include textBody2($weight: $font-weight-bold);
  color: $color-theme-primary;
  opacity: 0.8;
}

.footer__social {
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  justify-content: start;
}

.footer__social-link {
  color: $color-theme-primary;
}

.footer__social-icon {
  display: block;
  height: 32px;
  width: 32px;
}

.footer__app-links-wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: 134px 120px;
  padding-bottom: 8px;
}

.footer__app-links-description {
  @include textBody1($color: $color-black-50);
  grid-column: 1 / -1;
  line-height: 40px;
}

.footer__app-link,
.footer__app-icon {
  height: 40px;
}

.footer__app-icon_apple {
  width: 120px;
}

.footer__app-icon_google {
  width: 134px;
}

@media (min-width: 940px) {
  html[dir="ltr"] {
    @include tabletFooter();
  }
}

@media (min-width: 955px) {
  html[dir="rtl"] {
    @include tabletFooter("right");
  }
}
