@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.print-invoice-page {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  height: 100%;
  justify-items: center;
  overflow-y: auto;
  padding: 40px 0 70px;
}

.print-invoice-page__wrapper {
  box-sizing: border-box;
  display: grid;
  gap: 28px;
  max-width: 728px;
  padding: 20px 16px 0;
  width: 100%;

  @include tablet() {
    padding: 20px 32px 0;
  }
}

.print-invoice-page__logo {
  justify-self: center;
}

.print-invoice-page__title {
  @include reset-heading();
  @include textH5($weight: $font-weight-bold);
  border-bottom: 1px solid $color-borders-light;
  padding-bottom: 28px;
}
