@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.input-placeholder {
  @include textAccent($color: $color-black-50);
  left: 12px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.input-placeholder_invalid {
  color: $color-red-light;
}
