@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";

.account-tab-section__buttons-wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: 280px;
  justify-content: center;

  @include tablet() {
    border-top: 1px solid $color-borders-light;
    gap: 20px;
    grid-template-columns: 280px 280px;
    padding-top: 28px;
  }
}
