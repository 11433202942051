@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/text.scss";

.sidebar-payments-table {
  padding-bottom: 16px;
}

.sidebar-payments-table_print {
  @include tablet() {
    .table-header_sidebar-payments .table-header__row,
    .table-row_sidebar-payments {
      grid-template-columns: $table-grid-print;
    }
  }
}

.sidebar-payments-table__actions {
  display: grid;

  @include tablet-max() {
    align-content: space-between;
    gap: 12px;
    grid-template-columns: repeat(3, 1fr);
  }

  @include tablet() {
    gap: 12px;
    grid-template-columns: repeat(3, 24px) 106px;
  }

  &.sidebar-payments-table__actions_print {
    grid-template-columns: initial;
  }
}

.sidebar-payments-table__button {
  @include tablet-max() {
    grid-row: 2/3;
    height: 28px;
    width: 28px;
  }
}

.sidebar-payments-table__status {
  @include tablet-max() {
    grid-column: 1/4;
    justify-self: end;
  }
}

@include tablet-max() {
  .sidebar-payments-table__cell {
    .table-col__wrapper::before {
      @include textBody($color: $color-black-50);
      display: inline-block;
      line-height: 24px;
      width: 68px;
    }
  }

  .sidebar-payments-table__cell_date {
    grid-area: payment-date;
    padding-bottom: 4px;

    .table-col__text {
      @include textAccent($weight: $font-weight-medium);
    }
  }

  .sidebar-payments-table__cell_amount {
    grid-area: payment-amount;

    .table-col__wrapper::before {
      content: "Amount:";
    }
  }

  .sidebar-payments-table__cell_type {
    grid-area: payment-type;

    .table-col__wrapper {
      align-items: start;

      &::before {
        content: "Type:";
      }
    }

    .table-col__text,
    .table-multidata__main-text {
      font-weight: $font-weight-medium;
      line-height: 24px;
    }

    .table-multidata_payments {
      gap: 0;
    }
  }

  .sidebar-payments-table__cell_actions {
    grid-area: payment-actions;

    .table-col__wrapper {
      align-items: stretch;
      height: 100%;
    }
  }
}
