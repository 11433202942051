@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.sidebar-header {
  background-color: $color-black-0;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr max-content;
  padding: 32px 16px 20px;
  z-index: 21;

  @include tablet() {
    gap: 32px;
    padding: 32px;
  }
}

.sidebar-header__content {
  align-items: center;
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr;

  @include tablet() {
    gap: 24px;
  }
}

.sidebar-header__content_info {
  gap: 16px;
  grid-auto-flow: column;
  grid-template-columns: auto;

  @include tablet-max() {
    padding: 6px 0;
  }
}

.sidebar-header__title {
  @include reset-heading();
  @include textAccent($weight: $font-weight-bold);
  word-break: break-word;

  @include tablet() {
    @include textSubtitle($weight: $font-weight-bold);
    line-height: 24px;
  }
}

.sidebar-header__status {
  @include tablet-max() {
    display: none;
  }
}

.sidebar-header__icon-button {
  @include tablet-max() {
    &,
    svg {
      height: 24px;
      width: 24px;
    }
  }
}
