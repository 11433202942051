.payment-layout {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.payment-layout__content {
  display: grid;
}
