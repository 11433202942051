@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";

.mobile-card {
  background-color: $color-black-0;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  display: grid;
  gap: 8px;
  padding: 12px;
}

.mobile-card__header {
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  justify-content: space-between;
}

.mobile-card__date {
  @include reset-description();
  align-content: center;
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
}

.mobile-card__date-text {
  @include textAccent($weight: $font-weight-medium);
}

.mobile-card__container {
  align-items: start;
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  justify-content: space-between;
}

.mobile-card__persons-wrapper {
  display: grid;
  gap: 8px;
}

.mobile-card__person {
  display: grid;
  justify-content: start;
}

.mobile-card__person-title {
  @include reset-description();
  @include textCaption();
}

.mobile-card__person-name {
  @include reset-description();
  @include textBody($weight: $font-weight-medium);
  word-break: break-word;
}

.mobile-card__person-phone {
  @include reset-description();
  @include textCaption($color: $color-black-50);
  line-height: 20px;
}

.mobile-card__info-list {
  @include reset-list();
  background-color: $color-primary-light-alpha;
  border-radius: 8px;
  box-sizing: border-box;
  display: grid;
  gap: 2px;
  max-width: 144px;
  padding: 8px 12px;
}

.mobile-card__info-list-item {
  @include reset-list-item();
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: 52px minmax(60px, auto);
}

.mobile-card__info-label {
  @include textCaption();
}

.mobile-card__info-value {
  @include textBody($weight: $font-weight-medium);
  word-break: break-word;
}
