@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/text.scss";

.financials {
  background-color: $color-black-0;
  display: grid;
  grid-auto-flow: column;

  @include tablet-max() {
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    gap: 8px;
    padding: 11px;
  }
}

.financials_main {
  @include tablet-max() {
    border-color: transparent;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
  }

  @include tablet() {
    gap: 20px;
    padding: 10px 20px;

    .financials__info {
      gap: 6px;
    }

    .financials__info-name {
      @include textBody2($color: $color-black-50);
    }

    .financials__info-value {
      @include textBody2($weight: $font-weight-semibold);
    }
  }
}

.financials_sidebar {
  @include tablet() {
    gap: 40px;
    justify-content: start;
    padding: 12px 0;
  }

  @include tablet-max() {
    border-color: $color-borders-light;
  }
}

.financials__info {
  align-items: center;
  display: grid;
  gap: 2px;
  text-align: center;

  @include tablet() {
    gap: 10px;
    grid-auto-flow: column;
    text-align: left;
  }
}

.financials__info-name {
  @include textBody($color: $color-black-50);
}

.financials__info-value {
  @include textAccent($color: $color-black-90, $weight: $font-weight-bold);
  min-width: 60px;
  word-wrap: break-word;
}
