@import "src/scss/variables.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.mobile-menu__items {
  display: grid;
  gap: 16px;
  list-style: none;
  margin: 0;
  padding: 12px 16px 24px;
}

.mobile-menu__footer {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto;
  padding: 16px;
  position: relative;

  &::before {
    background-color: $color-primary-dark-beta;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: calc(100% - 32px);
  }
}

.mobile-menu__intro {
  @include reset-description();
  @include textCaption($color: $color-text-surface);
  align-content: start;
  display: grid;
  gap: 2px;
}

.mobile-menu__intro-name {
  @include textBody($color: $color-text-surface, $weight: $font-weight-medium);
}

.mobile-menu__button {
  color: $color-primary-light-basic;
}
