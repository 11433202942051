@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/breakpoints.scss";

.payment-page {
  display: grid;
  grid-template-rows: 1fr auto;
}

.payment-page__wrapper {
  align-content: start;
  display: grid;
  gap: 28px;
  justify-self: center;
  padding: 28px 16px 48px;

  @include tablet() {
    gap: 40px;
    padding: 40px 0;
    width: 520px;
  }
}

.payment-page__heading {
  display: grid;
  gap: 8px;
}

.payment-page__title {
  display: grid;
  gap: 8px;
  justify-items: center;

  @include tablet() {
    align-items: center;
    grid-auto-flow: column;
    justify-content: space-between;
    min-height: 48px;
  }
}

.payment-page__name {
  @include reset-heading();
  @include textH5($weight: $font-weight-bold);
  word-break: break-word;

  @include tablet() {
    @include textH1($weight: $font-weight-bold);
  }
}

.payment-page__share-button {
  height: 32px;
  width: 32px;

  svg {
    height: 12px;
    width: 12px;
  }

  @include tablet() {
    height: 40px;
    width: 40px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.payment-page__subtitle {
  @include textAccent();
  text-align: center;

  @include tablet() {
    @include textSubtitle();
    text-align: start;
  }
}

.payment-page__paid {
  @include textAccent($color: $color-text-caption);
  background-color: $color-black-5;
  border-radius: 8px;
  padding: 14px 12px;
  text-align: center;
}

.payment-page__actions {
  display: grid;
  gap: 20px;

  @include tablet() {
    grid-template-columns: 1fr 1fr;
  }
}
