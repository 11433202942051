@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.payment-status-page {
  align-content: start;
  display: grid;
  flex-grow: 1;
  gap: 48px;
  justify-items: center;
  padding: calc(var(--vh, 1vh) * 13.6) 16px 16px;

  @include tablet() {
    align-content: center;
    padding: 40px 32px 84px;
  }
}

.payment-status-page__info {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  justify-items: center;
  padding-top: 140px;
  position: relative;

  &::after {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 100px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100px;
  }
}

.payment-status-page__title {
  @include reset-heading();
  @include textH5($weight: $font-weight-bold);
}

.payment-status-page__text {
  @include reset-description();
  @include textAccent();
}

.payment-status-page__button {
  width: 200px;
}

.payment-status-page_successful {
  .payment-status-page__info::after {
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)' stroke='%23C2C2C2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M34.375 53.125 43.75 62.5l21.875-21.875'/%3E%3Cpath d='M50 87.5c20.71 0 37.5-16.79 37.5-37.5S70.71 12.5 50 12.5 12.5 29.29 12.5 50 29.29 87.5 50 87.5Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v100H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  }
}

.payment-status-page_failed {
  .payment-status-page__info::after {
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)' stroke='%23D95151' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m62.5 37.5-25 25M37.5 37.5l25 25M50 87.5c20.71 0 37.5-16.79 37.5-37.5S70.71 12.5 50 12.5 12.5 29.29 12.5 50 29.29 87.5 50 87.5Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v100H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  }
}
