@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";

.main-layout {
  display: grid;
  grid-auto-rows: auto 1fr auto;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;

  @include tablet() {
    grid-template-areas:
      "."
      "toppanel"
      ".";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
  }

  @include laptop() {
    grid-template-areas:
      "navbar toppanel"
      "navbar .";
    grid-template-columns: $navbar-width 1fr;
    grid-template-rows: auto 1fr;
  }
}

.main-layout__content {
  overflow-y: auto;
}
