@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

$halo-size: 40px;

.checkbox {
  position: relative;
}

.checkbox__input {
  height: 0;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.checkbox__texts {
  @include textBody();
  align-items: center;
  display: grid;
  gap: 6px;
  grid-template-columns: 1fr;
}

.checkbox__label {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: grid;
  gap: 12px;
  grid-template-columns: 24px 1fr;
  text-align: left;
  width: 100%;
}

.checkbox__icon-wrapper {
  box-sizing: border-box;
  display: flex;
  height: 24px;
  padding: 2px;
  width: 24px;
}

.checkbox__square-icon {
  background: $color-black-0;
  border: 1px solid $color-borders-basic;
  border-radius: 4px;
  box-sizing: border-box;
  height: 20px;
  position: relative;
  transition: all ease-in-out $transition-time;
  width: 20px;

  &::after,
  &::before {
    background: rgba($color: $color-black-75, $alpha: 0.04);
    border-radius: 50%;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all ease-in-out $transition-time;
    width: 0;
    z-index: -1;
  }
}

.checkbox__check-icon {
  border: none;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.checkbox_without-label {
  .checkbox__label {
    grid-template-columns: 1fr;
  }
}

// CHECKED
.checkbox.checkbox_checked {
  .checkbox__check-icon {
    background: transparent;
    border-bottom: 1px solid $color-black-75;
    border-left: 1px solid $color-black-75;
    height: 5px;
    transform: translate(-50%, -50%) rotate(-45deg) translate(1px, -1px);
    width: 10px;
  }
}

// SEMI
.checkbox.checkbox_semi {
  .checkbox__check-icon {
    background: transparent;
    border-radius: 1px;
    box-sizing: border-box;
    height: 8px;
    transform: translate(-50%, -50%);
    width: 8px;
  }
}

.checkbox.checkbox_semi.checkbox_checked {
  .checkbox__check-icon {
    background: $color-black-75;
    border: 1px solid $color-black-75;
  }
}

// ERROR
.checkbox_error:not(.checkbox_disabled) {
  .checkbox__texts {
    color: $color-red;
  }

  .checkbox__square-icon {
    border-color: $color-red-light;

    &::after,
    &::before {
      background: rgba($color: $color-red, $alpha: 0.06);
    }
  }
}

.checkbox_error:not(.checkbox_disabled).checkbox_checked {
  .checkbox__check-icon {
    border-color: $color-red;
  }
}

.checkbox_error:not(.checkbox_disabled).checkbox_semi.checkbox_checked {
  .checkbox__check-icon {
    background-color: $color-red;
    border-color: $color-red;
  }
}

// DISABLED
.checkbox_disabled {
  .checkbox__texts {
    color: $color-black-20;
  }

  .checkbox__label {
    cursor: default;
  }

  .checkbox__square-icon {
    background: $color-black-6;
    border-color: $color-black-6;
  }
}

.checkbox_disabled.checkbox_checked {
  .checkbox__check-icon {
    border-bottom: 1px solid $color-black-20;
    border-left: 1px solid $color-black-20;
  }
}

.checkbox_disabled.checkbox_semi.checkbox_checked {
  .checkbox__check-icon {
    background: $color-black-20;
    border: 1px solid $color-black-20;
  }
}

@media not print {
  .checkbox:not(.checkbox_disabled) {
    .checkbox__label:hover {
      .checkbox__square-icon {
        border-color: $color-borders-dark;

        &::before {
          height: $halo-size;
          width: $halo-size;
        }
      }
    }

    .checkbox__label:active {
      .checkbox__square-icon {
        border-color: $color-borders-dark;

        &::after {
          height: $halo-size;
          width: $halo-size;
        }
      }
    }
  }

  .checkbox__input:focus-visible + .checkbox__texts {
    .checkbox__square-icon {
      border-color: $color-borders-dark;

      &::after,
      &::before {
        height: $halo-size;
        width: $halo-size;
      }
    }
  }

  .checkbox_error:not(.checkbox_disabled) {
    .checkbox__label:active,
    .checkbox__label:hover {
      .checkbox__square-icon {
        border-color: $color-red;
      }
    }

    .checkbox__input:focus-visible + .checkbox__texts {
      .checkbox__square-icon {
        border-color: $color-red;
      }
    }
  }
}
