@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.table-multidata {
  display: grid;
}

.table-multidata__main-text {
  @include textBody();
  align-items: center;
  box-sizing: border-box;
  display: flex;
  min-height: 24px;
  word-break: break-word;
}

.table-multidata__caption {
  @include textCaption($color: $color-text-caption);
  align-items: center;
  display: grid;
  min-height: 20px;
}

.table-multidata_fade {
  .table-multidata__main-text {
    color: $color-black-50;
  }
}

.table-multidata_sidebar-payments {
  gap: 2px;

  .table-multidata__main-text {
    line-height: 18px;
    min-height: initial;
  }

  .table-multidata__caption {
    line-height: 18px;
    min-height: initial;
  }
}
