@import "src/scss/variables.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.mobile-header {
  background-color: $color-theme-primary;
  z-index: 999;
}

.mobile-header__wrapper {
  box-sizing: border-box;
  padding: 38px 44px 26px;
  position: relative;
}

.mobile-header_shown-menu {
  .mobile-header__hamburger {
    &::before,
    &::after {
      left: 50%;
      top: 50%;
      width: 100%;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.mobile-header__logo {
  bottom: 20px;
  left: 10px;
  position: absolute;
}

.mobile-header__title {
  @include reset-description();
  @include textAccent($color: $color-text-surface, $weight: $font-weight-bold);
  text-align: center;
}

.mobile-header__hamburger {
  @include reset-button();
  bottom: 20px;
  height: 36px;
  position: absolute;
  right: 16px;
  width: 24px;

  &::before,
  &::after {
    background: $color-primary-light-basic;
    border-radius: 2px;
    content: "";
    display: block;
    height: 2px;
    left: 2px;
    position: absolute;
    right: 2px;
    transform-origin: center;
    transition: all $transition-time;
  }

  &::before {
    top: 14px;
  }

  &::after {
    bottom: 14px;
  }
}

.mobile-header__dropdown {
  background-color: $color-theme-primary;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  transform: translateY(100%);
  transition: opacity ease-out $transition-time-long;
  visibility: hidden;
  width: 100%;
}

.mobile-header__dropdown_shown {
  opacity: 1;
  visibility: visible;
}
