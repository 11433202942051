@import "src/scss/variables.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.crm-url {
  align-items: center;
  display: grid;
  justify-content: start; // impacts tooltip position
}

.crm-url__title {
  @include reset-heading();
  @include textBody($color: $color-text-caption);
}

.crm-url__actions {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  justify-content: start;
}
