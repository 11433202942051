@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/breakpoints.scss";

.payment-receipt {
  border: 1px solid $color-borders-light;
  border-radius: 8px;
  box-sizing: border-box;
  display: grid;
  gap: 20px;
  min-width: 328px;
  padding: 19px 15px;

  @include tablet() {
    padding: 27px;
  }
}

.payment-receipt__block {
  display: grid;
  gap: 20px;
  padding-bottom: 20px;

  &:last-of-type {
    padding-bottom: 0;
  }
}

.payment-receipt__block_with-border {
  border-bottom: 1px dashed $color-borders-light;
  padding-bottom: 19px;
}

.payment-receipt__title {
  @include reset-heading();
  @include textSubtitle($font-family: $mono-font, $font-size: 18px, $weight: $font-weight-bold);
  letter-spacing: 0.1px;

  @include tablet() {
    font-size: 20px;
  }
}

.payment-receipt__content {
  display: grid;
  gap: 12px;
}

.payment-receipt__info {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-auto-flow: column;
  justify-content: space-between;
}

.payment-receipt__info_row {
  gap: 2px;
  grid-auto-flow: initial;

  @include tablet() {
    gap: 20px;
    grid-auto-flow: column;
  }
}

.payment-receipt__name {
  @include textBody2($font-family: $mono-font, $color: $color-text-caption);
  letter-spacing: 0.1px;

  @include tablet() {
    @include textBody1($font-family: $mono-font, $color: $color-text-caption);
  }
}

.payment-receipt__name_product {
  line-height: 24px;
}

.payment-receipt__value {
  @include textBody2($font-family: $mono-font, $weight: $font-weight-medium);
  letter-spacing: 0.1px;

  @include tablet() {
    @include textBody1($font-family: $mono-font, $weight: $font-weight-medium);
  }
}

.payment-receipt__name_total,
.payment-receipt__value_total {
  font-size: 16px;

  @include tablet() {
    font-size: 18px;
  }
}

.payment-receipt__value_total {
  font-weight: $font-weight-bold;
}
