@import "src/scss/variables.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.button {
  @include reset-button();
  @include focus();
  border-radius: 8px;
  box-sizing: border-box;
  padding: 9px 10px;
  position: relative;
  transition:
    background-color ease-in-out $transition-time,
    border-color ease-in-out $transition-time,
    box-shadow ease-in-out $transition-time;
}

.button__wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.button__icon {
  display: flex;
  left: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.button_link {
  display: inline-flex;
}

.button_regular {
  min-height: 40px;
}

.button_big {
  min-height: 52px;

  .button__label {
    font-size: 15px;
  }
}

.button_icon {
  padding-left: 40px;
  padding-right: 40px;
}

.button_filled {
  @include textAssistive($weight: $font-weight-semibold, $color: $color-black-5);
  background-color: $color-theme-primary;
  box-shadow: 0 3px 8px rgba($color-black, 0.12);

  &:hover:not(.button_disabled) {
    background-color: $color-theme-secondary;
  }

  &:active:not(.button_disabled) {
    background-color: $color-theme-variant;
  }
}

.button_outlined {
  @include textAssistive($weight: $font-weight-semibold);
  background: transparent;
  border: 1px solid $color-black-10;

  &:hover:not(.button_disabled) {
    border-color: $color-black-30;
    color: $color-black-90;
  }

  &:active:not(.button_disabled) {
    border-color: $color-theme-variant;
    color: $color-theme-variant;
  }
}

.button_disabled {
  background: $color-black-5;
  border-color: $color-black-5;
  box-shadow: none;
  color: $color-text-disabled;
  cursor: default;
}
