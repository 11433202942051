@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";

.main-page {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.main-page__filters {
  @include tablet-max() {
    display: none;
  }
}

.main-page__content {
  align-content: start;
  background-color: $color-primary-light-alpha;
  box-sizing: border-box;
  display: grid;
  flex-grow: 1;
  gap: 16px;
  overflow: auto;
  padding: 20px 16px 24px;
  position: relative;

  @include tablet() {
    background-color: $color-black-0;
    display: block;
    padding: 0;
  }
}
