@import "src/scss/variables.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.menu-item {
  @include reset-list-item();
}

.menu-item__link {
  @include reset-link();
  @include focus();
  @include textAccent($weight: 500, $color: $color-text-surface);
  align-items: center;
  border-radius: 8px;
  color: $color-primary-light-basic;
  cursor: pointer;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  height: 24px;
  justify-content: left;

  svg {
    color: $color-black-50;
  }
}

.menu-item__button {
  @include reset-button();
  @include focus();
  align-items: center;
  border-radius: 8px;
  color: $color-primary-light-basic;
  cursor: pointer;
  display: grid;
  height: 36px;
  justify-items: center;
  transition: background-color $transition-time;
  width: 36px;

  &:hover:not(:disabled) {
    background-color: $color-primary-dark-alpha;
  }
}
