@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.filter-block {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 20px;
}

.filter-block__title {
  @include textBody($weight: $font-weight-bold);
}
