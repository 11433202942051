@import "src/scss/variables.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/reset.scss";

.icon-button {
  @include reset-button();
  @include focus();
  align-items: center;
  background: transparent;
  border-radius: 4px;
  color: $color-black-75;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;

  svg {
    height: 20px;
    transition: color $transition-time;
    width: 20px;
  }
}

.icon-button_disabled {
  color: $color-text-disabled;
  cursor: default;
  pointer-events: none;
}

@media not print {
  .icon-button_halo {
    $halo-size: 40px;
    position: relative;

    &::after,
    &::before {
      background-color: $color-black-75;
      border-radius: 50%;
      content: "";
      height: 0;
      left: 50%;
      opacity: 0.04;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all ease-in-out $transition-time;
      width: 0;
    }

    &:hover {
      &::after {
        height: $halo-size;
        width: $halo-size;
      }
    }

    &:active {
      &::before {
        height: $halo-size;
        width: $halo-size;
      }
    }
  }
}

.icon-button_plain {
  height: 20px;
  width: 20px;
}

.icon-button_square {
  background-color: $color-icon-button-regular;
  border-radius: 8px;
  height: 24px;
  transition: background-color $transition-time;
  width: 24px;

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    background-color: $color-icon-button-hover;
  }

  &:active {
    background-color: $color-icon-button-click;
  }
}
