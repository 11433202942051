@import "src/scss/variables.scss";

@mixin tablet-max {
  @media screen and (max-width: ($tablet-width - 1px)) {
    @content;
  }
}

@mixin laptop-max {
  @media (max-width: ($laptop-width - 1px)) {
    @content;
  }
}

@mixin desktop-max {
  @media (max-width: ($desktop-width - 1px)) {
    @content;
  }
}

@mixin tablet {
  @media print, (min-width: $tablet-width) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: $laptop-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}
