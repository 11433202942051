@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";

.meshulam-form__frame {
  border: 1px solid $color-borders-light;
  border-radius: 8px;
  box-sizing: border-box;
  height: calc((100vw - 32px) * 1.4);
  width: calc(100vw - 32px);

  @media (min-width: 584px) {
    height: 770px;
    width: 550px;
  }

  @include tablet() {
    height: 728px;
    width: 520px;
  }
}
