@import "src/scss/mixins/breakpoints.scss";

.language-toggler {
  direction: ltr;
  display: grid;
  gap: 4px;
  grid-auto-flow: column;

  @include tablet() {
    gap: 8px;
  }
}
