@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.info-block {
  align-content: start;
  align-items: center;
  background-color: $color-black-0;
  box-sizing: border-box;
  display: grid;

  @include tablet() {
    padding-bottom: 12px;

    &:first-child {
      padding-top: 16px;
    }
  }
}

.info-block_with-title {
  padding-bottom: 24px;

  @include tablet() {
    padding-bottom: 32px;
  }
}

.info-block_payments {
  padding-bottom: 0;

  @include tablet() {
    .info-block__title {
      padding-bottom: 16px;
    }
  }
}

.info-block_with_gap {
  gap: 12px;
}

.info-block__title {
  @include reset-heading();
  @include textAccent($weight: $font-weight-bold, $color: $color-black-90);
  border-bottom: 1px solid $color-borders-light;
  box-sizing: border-box;
  padding-bottom: 12px;
}

.info-block__title_no-underline {
  @media not print {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
