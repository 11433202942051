@import "src/scss/variables.scss";
@import "src/scss/animations.scss";

.mobile-search-window {
  animation: fadeIn ease-out forwards $transition-time-long;
  background-color: $color-black-0;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1001;
}

.mobile-search-window__wrapper {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.mobile-search-window__result {
  background-color: $color-primary-light-alpha;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  padding: 20px 16px;
}

.mobile-search-window_hiding {
  animation: fadeOut ease-out forwards $transition-time-long;
}
