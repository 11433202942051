@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/text.scss";

.input {
  align-items: center;
  background-color: $color-black-0;
  border: 1px solid $color-borders-basic;
  border-radius: 8px;
  box-sizing: border-box;
  display: grid;
  height: 52px;
  padding: 0 12px;
  position: relative;
  transition: border-color $transition-time ease-in-out;
  width: 100%;

  svg {
    color: $color-black-75;
  }

  &:hover {
    border-color: $color-borders-dark;
  }

  &:not(.input_disabled):focus-within {
    border-color: transparent;
    box-shadow: $focus-shadow;
  }
}

.input__field {
  @include textAccent();
  border: none;
  margin: 0;
  outline: none;
  padding: 0;
  width: 100%;
}

.input_flattened {
  height: 40px;
}

.input_invalid {
  border-color: $color-red-light;

  &:hover {
    border-color: $color-red;
  }

  .input__field {
    color: $color-red;
  }
}

.input_disabled {
  background: $color-black-5;
  border-color: $color-black-5;

  &:hover {
    border-color: $color-black-5;
  }
}

.input_icon {
  gap: 12px;
  grid-template-columns: 1fr max-content;
}

.input_search {
  gap: 12px;
  grid-template-columns: max-content 1fr;
  width: 280px;

  svg {
    color: #a6a7a9;
  }
}

.input_multi {
  height: auto;
  padding: 16px 12px;
  position: relative;

  &.input_with-counter {
    padding: 16px 12px 24px;
  }

  .input__textarea {
    @include textAccent();
    border: none;
    border-radius: inherit;
    line-height: 24px;
    outline: none;
    resize: none;
  }
}

.input__counter {
  @include textCaption($color: $color-black-50);
  bottom: 8px;
  height: 16px;
  position: absolute;
  right: 12px;
  text-align: right;
}

.input_disabled .input__field,
.input_disabled .input__value,
.input_disabled .input-placeholder,
.input_disabled .input__textarea {
  background: $color-black-5;
  color: $color-black-20;
}

.input_disabled svg {
  color: $color-black-20;
}
