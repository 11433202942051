@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.table-header {
  background-color: $color-black-0;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 14;
}

.table-header_main {
  min-width: 976px;
  overflow: auto;

  .table-header__row {
    grid-gap: 20px;
    grid-template-columns: $table-grid-main;
    padding: 16px 32px;
  }
}

.table-header_payments {
  min-width: 976px;
  overflow: auto;

  .table-header__row {
    grid-gap: 20px;
    grid-template-columns: $table-grid-payments;
    padding: 16px 32px;
  }

  .table-header__col {
    &:nth-last-of-type(2) {
      text-align: center;
    }
  }
}

.table-header_sidebar-payments {
  display: none;

  @include tablet() {
    display: block;
  }

  .table-header__row {
    grid-gap: 24px;
    grid-template-columns: $table-grid-sidebar-payments;
    padding: 16px 12px;
  }

  .table-header__col {
    &:last-of-type {
      padding-right: 36px;
      text-align: right;
    }
  }
}

.table-header__row {
  align-items: center;
  box-sizing: border-box;
  display: grid;
}

.table-header__col {
  @include textCaption($color: $color-text-caption);
  padding: 0;

  &:last-of-type {
    text-align: center;
  }
}
