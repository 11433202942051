@import "src/scss/variables.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.summary-product-list {
  @include reset-list();
  display: grid;
  gap: 1px;
}

.summary-product-list__item {
  @include reset-list-item();
  border-bottom: 1px solid $color-black-5;
  padding: 11px 0;

  &:last-of-type {
    border-color: $color-black-10;
  }
}

.summary-product-list__name {
  @include textBody();
}
