@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.radiobox {
  position: relative;
}

.radiobox__input {
  height: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.radiobox__label {
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  justify-content: start;
}

.radiobox__label-text {
  @include textBody();
}

.radiobox__label-icon {
  box-sizing: border-box;
  display: flex;
  height: 24px;
  padding: 2px;
  position: relative;
  width: 24px;
}

.radiobox__label-icon-wrapper {
  background-color: $color-black-0;
  border: 1px solid $color-borders-basic;
  border-radius: 50%;
  box-sizing: border-box;
  height: 20px;
  position: relative;
  transition: border-color $transition-time ease-in-out;
  width: 20px;

  &::before {
    background-color: $color-black-75;
    border-radius: 50%;
    content: "";
    height: 40px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity ease-out $transition-time;
    width: 40px;
    z-index: -1;
  }

  &::after {
    background-color: $color-black-75;
    border-radius: 50%;
    content: "";
    height: 8px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity ease-out $transition-time;
    width: 8px;
    z-index: 2;
  }
}

.radiobox:hover:not(.radiobox_disabled) .radiobox__label-icon-wrapper {
  border-color: $color-borders-dark;

  &::before {
    opacity: 0.04;
  }
}

.radiobox__input:focus-visible + .radiobox__label {
  .radiobox__label-icon-wrapper {
    border-color: $color-borders-dark;

    &::before {
      opacity: 0.04;
    }
  }
}

.radiobox_checked {
  .radiobox__label-icon-wrapper::after {
    opacity: 1;
  }
}

.radiobox_disabled {
  .radiobox__label {
    cursor: default;
  }

  .radiobox__label-icon-wrapper {
    background-color: $color-primary-light-alpha;
    border-color: $color-primary-light-alpha;

    &::after {
      background-color: $color-black-20;
    }
  }
}
