@import "src/scss/mixins/reset.scss";

.mobile-list {
  @include reset-list();
  display: grid;
  gap: 12px;
}

.mobile-list_empty {
  align-content: center;
  height: 100%;
  justify-content: center;
}

.mobile-list__list-item {
  @include reset-list-item();
}
