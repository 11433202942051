@import "src/scss/variables.scss";
@import "src/scss/animations.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/text.scss";

.sidebar {
  box-sizing: border-box;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1002;
}

.sidebar__shadow {
  @include tablet() {
    animation: $transition-time ease-in-out forwards fadeIn;
    background-color: rgba($color: $color-black, $alpha: 0.45);
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

.sidebar__content {
  animation: fadeIn ease-out forwards $transition-time-long;
  background-color: $color-black-0;
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.16),
    0 -1px 4px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  height: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;

  @include tablet() {
    animation: $transition-time ease-in-out forwards showFromRight;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
    opacity: 1;
    transform: translateX(100%);
    width: 728px;
  }
}

.sidebar__content-wrapper {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
}

.sidebar__content-body {
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  padding: 20px 16px 40px;

  @include tablet() {
    padding: 0 32px 32px;
  }
}

.sidebar_hide {
  .sidebar__content {
    animation: fadeOut ease-out forwards $transition-time-long;
  }

  @include tablet() {
    .sidebar__content {
      animation: $transition-time ease-in-out forwards hideToRight;
    }

    .sidebar__shadow {
      animation: $transition-time ease-in-out forwards fadeOut;
    }
  }
}
