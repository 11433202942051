// FONT FAMILY
$font-main: "Poppins", "Open Sans", "Arial", sans-serif;
$mono-font: "Roboto Mono", "Open Sans", monospace;

// FONT WEIGHTS
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// THEME COLORS
$color-theme-primary: var(--color-theme-accent);
$color-theme-primary-rgb: var(--color-theme-accent-rgb);
$color-theme-light: var(--color-theme-light);
$color-theme-secondary: var(--color-theme-secondary);
$color-theme-secondary-rgb: var(--color-theme-secondary-rgb);
$color-theme-variant: var(--color-theme-variant);
$color-theme-variant-rgb: var(--color-theme-variant-rgb);
$color-theme-assist: var(--color-theme-assist);

// GREY COLORS
$color-black: #000000;
$color-black-90: #222222;
$color-black-75: #3b3b3b;
$color-black-50: #808080;
$color-black-40: #bfbfbf;
$color-black-30: #b9b9b9;
$color-black-20: #cdcdcd;
$color-black-11: #e3e3e3;
$color-black-10: #e5e5e5;
$color-black-6: #f6f6f6;
$color-black-5: #f7f7f7;
$color-black-0: #ffffff;

// OTHER COLORS
$color-red: #d95151;
$color-red-light: #f9919a;
$color-outline: #35cdfd;
$color-orange: #ff8800;
$color-green: #138736;
$color-blue: #2b7fcc;

// ICON BUTTON COLORS
$color-icon-button-regular: rgba(#b0b0b0, 0.08);
$color-icon-button-hover: rgba(#a6a7a9, 0.16);
$color-icon-button-click: rgba(#a6a7a9, 0.2);

// PRIMARY DARK COLORS
$color-primary-dark-basic: $color-black-75;
$color-primary-dark-alpha: #4e4e4e;
$color-primary-dark-beta: #676767;
$color-primary-dark-gamma: #838383;
$color-primary-dark-delta: #979797;

// PRIMARY LIGHT COLORS
$color-primary-light-basic: #fafafa;
$color-primary-light-alpha: #f6f6f6;
$color-primary-light-beta: #e8e8e8;
$color-primary-light-gamma: #d8d8d8;
$color-primary-light-delta: #c2c2c2;

// TEXT COLORS
$color-text-body: $color-black-75;
$color-text-caption: $color-black-50;
$color-text-light: $color-black-30;
$color-text-light-disabled: $color-black-11;
$color-text-disabled: $color-black-20;
$color-text-surface: $color-black-5;

// BORDERS COLORS
$color-borders-basic: #b0b0b0;
$color-borders-light: $color-black-10;
$color-borders-dark: #6f6f6f;

// INVOICE STATUS COLORS
$color-invoice-status-canceled: #7a7b7f;
$color-invoice-status-pending: $color-blue;
$color-invoice-status-paid-full: $color-green;
$color-invoice-status-paid-partially: $color-orange;

// PAYMENT STATUS COLORS
$color-payment-status-failed: $color-red;

// ALERT COLORS
$color-alert-error: $color-red;
$color-alert-error-background: #fdf6f6;
$color-alert-warning: #67b378;
$color-alert-warning-background: #f3f9f4;
$color-alert-info: #3179bc;
$color-alert-info-background: #f5f8fc;

// SHADOWS
$box-shadow:
  0 4px 12px 0 rgba(#000000, 0.1),
  0 4px 20px 0 rgba(#000000, 0.05);
$focus-shadow:
  0 0 0 1px $color-black-0,
  0 0 0 3px $color-outline;

// TRANSITION TIME
$transition-time: 0.15s;
$transition-time-long: 0.2s;

// BREAKPOINTS
$tablet-width: 801px;
$laptop-width: 1024px;
$desktop-width: 1280px;

// LAYOUT
$navbar-width: 48px;
$table-grid-main: minmax(84px, 8%) repeat(3, 1fr) repeat(3, 0.7fr) 140px;
$table-grid-payments: minmax(84px, 8%) repeat(3, 1fr) repeat(3, 0.7fr) 140px 48px;
$table-grid-sidebar-payments: repeat(3, 1fr) 214px;
$table-grid-print: repeat(3, 1fr) 106px;
$calendar-height: 362px;
