@use "sass:math";
@import "src/scss/variables.scss";
@import "src/scss/animations.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/text.scss";

.popup {
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1100;

  @include tablet() {
    align-items: center;
    animation: $transition-time forwards fadeIn;
    background-color: rgba($color: $color-black, $alpha: 0.45);
    opacity: 0;
  }
}

.popup__shadow {
  animation: fadeIn $transition-time-long ease-out forwards;
  background-color: rgba($color: $color-black, $alpha: 0.45);
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @include tablet() {
    display: none;
  }
}

.popup__shadow_dragging {
  animation-fill-mode: unset;
}

.popup__shadow_fade-in {
  opacity: 1;
  transition: opacity math.div($transition-time-long, 2);
}

.popup__shadow_fade-out {
  opacity: 0;
  transition: opacity math.div($transition-time-long, 2);
}

.popup__wrapper {
  align-content: start;
  animation: showFromBottom $transition-time-long ease-out forwards;
  background-color: $color-black-0;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  display: grid;
  gap: 24px;
  justify-items: center;
  min-height: auto;
  overflow: auto;
  padding: 12px 16px 32px;
  width: 100%;

  @include tablet-max() {
    transform: translateY(100%);

    &::before {
      background-color: $color-text-light-disabled;
      border-radius: 2px;
      content: "";
      cursor: grab;
      display: block;
      height: 4px;
      width: 32px;
    }
  }

  @include tablet() {
    animation: $transition-time forwards fadeIn;
    border-radius: 4px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
    gap: 20px;
    padding: 20px;
    width: 320px;
  }
}

.popup__wrapper_dragging {
  animation-fill-mode: unset;
}

.popup__wrapper_dragged-to-top {
  transform: translateY(0);
  transition: transform math.div($transition-time-long, 2);
}

.popup__wrapper_dragged-to-bottom {
  transform: translateY(100%);
  transition: transform math.div($transition-time-long, 2);
}

.popup__header {
  display: grid;
  gap: 12px;
  width: 100%;
}

.popup__title {
  @include textAccent($weight: $font-weight-bold);
  margin: 0 auto;
  text-align: center;
}

.popup__text {
  @include textBody();
  margin: 0;
  text-align: center;
}

.popup__buttons {
  display: grid;
  gap: 16px;
  grid-auto-columns: 1fr 1fr;
  grid-auto-flow: column;
  width: 100%;

  @include tablet() {
    gap: 20px;
  }

  .popup__button {
    max-width: unset;
  }
}

.popup__button {
  justify-self: center;
  max-width: 194px;
  width: 100%;
}

.popup__form {
  align-content: start;
  display: grid;
  gap: 20px;
  padding: 0;
}

.popup_hiding {
  @include tablet-max() {
    .popup__shadow {
      animation: fadeOut $transition-time-long ease-out both;
    }

    .popup__wrapper {
      animation: hideToBottom $transition-time-long ease-out both;
    }
  }

  @include tablet() {
    &,
    .popup__wrapper {
      animation: $transition-time both fadeOut;
    }
  }
}

.popup__icon {
  border-radius: 0;
  color: $color-black-75;
}

.popup_create-payment {
  .popup__wrapper {
    overflow: visible;

    @include tablet() {
      gap: 32px;
      padding: 32px;
      width: 464px;
    }
  }

  .popup__title {
    @include tablet() {
      @include textSubtitle($weight: $font-weight-bold);
    }
  }

  .popup__main {
    display: grid;
    gap: 24px;
    width: 100%;

    @include tablet() {
      gap: 20px;
    }
  }

  .popup__inputs {
    display: grid;
    gap: 24px;

    @include tablet() {
      gap: 32px;
    }
  }
}

.popup_sales-filters,
.popup_payments-filters {
  .popup__content {
    display: grid;
    gap: 24px;
    width: 100%;
  }
}

.popup_record-payment {
  .popup__wrapper {
    @include tablet() {
      gap: 32px;
      padding: 32px;
      width: 492px;
    }
  }

  .popup__title {
    @include tablet() {
      @include textSubtitle($weight: $font-weight-bold);
    }
  }

  .popup__main {
    display: grid;
    gap: 24px;
    width: 100%;

    @include tablet() {
      gap: 20px;
    }
  }

  .popup__content {
    display: grid;
    gap: 24px;
    width: 100%;

    @include tablet() {
      gap: 32px;
    }
  }

  .popup__method {
    display: grid;
    gap: 12px;
  }

  .popup__method-title {
    @include textAccent($weight: $font-weight-medium);
  }

  .popup__method-list {
    display: grid;
    gap: 16px 12px;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    @include tablet() {
      gap: 24px 22px;
      grid-template-columns: unset;
      grid-template-rows: unset;
      justify-content: space-between;
    }
  }
}

.popup_share {
  .popup__wrapper {
    width: 100%;

    @include tablet() {
      border-radius: 8px;
      gap: 40px;
      padding: 40px;
      width: 340px;
    }
  }

  .popup__title {
    @include textSubtitle($weight: $font-weight-bold);
  }

  .popup__text {
    @include textAccent();
  }
}

.popup_warn {
  .popup__button {
    @include tablet() {
      width: 134px;
    }
  }
}
