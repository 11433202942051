@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.status {
  @include textBody($weight: $font-weight-medium);
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-grid;
  padding: 2px 8px;
  text-align: center;
  width: 140px;
  word-break: break-word;
}

.status_canceled {
  background: rgba($color-invoice-status-canceled, 0.1);
  color: $color-invoice-status-canceled;
}

.status_pending-payment,
.status_unpaid {
  background: rgba($color-invoice-status-pending, 0.1);
  color: $color-invoice-status-pending;
}

.status_paid-in-full,
.status_paid {
  background: rgba($color-invoice-status-paid-full, 0.1);
  color: $color-invoice-status-paid-full;
}

.status_pending,
.status_partially-paid {
  background: rgba($color-invoice-status-paid-partially, 0.1);
  color: $color-invoice-status-paid-partially;
}

.status_failed {
  background: rgba($color-payment-status-failed, 0.1);
  color: $color-payment-status-failed;
}

.status_payment {
  width: 106px;
}

.status_printable {
  background-color: $color-black-0;
  color: $color-black-75;
  padding-left: 0;
  padding-right: 0;
}
