@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";

.table-row {
  align-items: center;
  background-color: $color-black-0;
  border-radius: 4px;
  box-sizing: border-box;
  display: grid;
  position: relative;
  transition: background-color $transition-time;

  &:nth-child(2n + 1):not(.table-row_sidebar-payments) {
    background-color: $color-primary-light-basic;
  }

  &:focus {
    outline: none;
  }

  &:hover:not(.table-row_sidebar-payments),
  &:focus-visible:not(.table-row_sidebar-payments) {
    background-color: $color-primary-light-alpha;
  }
}

.table-row_main {
  cursor: pointer;
  grid-template-columns: repeat(8, auto);

  @include tablet() {
    grid-gap: 20px;
    grid-template-columns: $table-grid-main;
    padding: 16px 32px;
  }
}

.table-row_payments {
  cursor: pointer;
  grid-template-columns: repeat(9, auto);

  @include tablet() {
    grid-gap: 20px;
    grid-template-columns: $table-grid-payments;
    padding: 16px 32px;
  }

  .table-col {
    &:last-of-type {
      .table-col__wrapper {
        justify-content: center;
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}

.table-row_sidebar-payments {
  gap: 4px 8px;

  @include tablet-max() {
    align-items: stretch;
    border-top: 1px solid $color-black-10;
    grid-template-areas:
      "payment-date payment-actions"
      "payment-amount payment-actions"
      "payment-type payment-actions";
    grid-template-columns: 1fr 108px;
    padding: 15px 0 16px;
  }

  @include tablet() {
    grid-gap: 24px;
    grid-template-columns: $table-grid-sidebar-payments;
    padding: 12px;
  }
}

.table-row__loader {
  position: absolute;
}
