@import "src/scss/variables.scss";

.payments-mobile-filters {
  align-content: end;
  align-items: center;
  background-color: $color-black-0;
  box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr auto;
  padding: 12px 16px 20px;
  z-index: 999;
}

.payments-mobile-filters__search-button {
  color: $color-primary-dark-alpha;
  height: 28px;
  width: 28px;

  svg {
    height: 100%;
    width: 100%;
  }
}
