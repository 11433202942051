@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.login-page {
  display: grid;
  position: relative;
  width: 100%;

  @include tablet() {
    grid-template-columns: 1fr 480px;

    &::before {
      background: url("~/public/main-bg/bezikaron-payments/bezikaron-payments-bg.jpg") no-repeat
        left top / cover;
      content: "";
    }
  }
}

.login-page__container {
  background-color: $color-black-0;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  width: 100%;
}

.login-page__content {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 32px;
  min-height: 100%;
  padding: calc(var(--vh, 1vh) * 10) 32px 48px;
  width: 100%;

  @include tablet() {
    gap: 60px;
    padding: 86px 40px 40px;
  }
}

.login-page__info-container {
  display: grid;
  gap: 32px;
  position: relative;

  @include tablet-max() {
    justify-items: center;
    text-align: center;
  }
}

.login-page__logo {
  justify-self: center;
}

.login-page__text-wrapper {
  align-items: center;
  display: grid;
  gap: 4px;
  justify-items: center;
}

.login-page__title {
  @include reset-heading();
  @include textH5($weight: $font-weight-bold);
}

.login-page__text {
  @include textBody1();
}
