@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.table-col {
  box-sizing: border-box;
  padding: 0;
}

.table-col__wrapper {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  min-height: 24px;
}

.table-col__text {
  @include textBody();
  word-break: break-word;
}

.table-col_bold {
  .table-col__text {
    font-weight: $font-weight-bold;
  }
}

.table-col_fade {
  .table-col__text {
    color: $color-black-50;
  }
}
