@import "src/scss/variables.scss";

.input-date-picker {
  .input__field {
    min-height: 50px;
  }

  .icon-button {
    color: $color-black-75;
    width: auto;
  }
}

.input-date-picker_flattened {
  .input__field {
    min-height: 38px;
  }
}
