.table {
  border-collapse: collapse;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.table_main,
.table_payments {
  min-width: 976px;
  overflow: auto;
}
