@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.description-list {
  @include reset-list();
  display: grid;
  gap: 8px;

  @include tablet() {
    gap: 2px;
  }
}

.description-list__item {
  @include reset-list-item();
  align-items: center;
  display: grid;
  gap: 4px;
  grid-template-columns: 100px auto;
  min-height: 24px;

  @include tablet() {
    grid-template-columns: 152px auto;
  }
}

.description-list__item_crm {
  @include tablet() {
    .description-list__value {
      padding: 4px 8px;
    }
  }
}

.description-list__key {
  @include textBody($color: $color-black-50);
}

.description-list__value {
  @include textBody();

  @include tablet() {
    padding: 6px 12px;
  }
}
