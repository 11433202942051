@import "src/scss/mixins/breakpoints.scss";

.filters-panel {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  width: 220px;

  &::before {
    background: linear-gradient(90deg, rgba(241, 241, 241, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: -16px;
    top: 0;
    width: 16px;
    z-index: 20; // more than table-header
  }

  @include laptop() {
    width: 250px;
  }
}

.filters-panel__wrapper {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 28px;
  grid-template-rows: 1fr auto;
  height: 100%;
  overflow: auto;
  padding: 16px;

  @include laptop() {
    padding: 20px;
  }
}

.filters-panel__main-content {
  align-content: start;
  display: grid;
  gap: 28px;
}
