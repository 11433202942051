@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.sidebar-tabs {
  @include reset-list();
  align-content: start;
  background-color: $color-black-0;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  padding: 0 16px 4px;

  @include tablet() {
    padding: 12px 32px 20px;
  }
}

.sidebar-tabs__item {
  @include reset-list-item();
}

.sidebar-tabs__button {
  @include textAssistive($weight: $font-weight-semibold);
  background-color: $color-black-0;
  width: 100%;

  &:hover:not(.button_disabled) {
    color: $color-theme-secondary;
  }
}

.sidebar-tabs__button_active {
  background-color: rgba($color-theme-variant-rgb, 0.08);
  color: $color-theme-primary;
  cursor: auto;
  pointer-events: none;
}
