@import "src/scss/mixins/breakpoints.scss";

.summary-tab-section__button-wrapper {
  display: grid;
  justify-items: center;
  padding-top: 8px;

  @include tablet() {
    padding-top: 12px;
  }
}

.summary-tab-section__button {
  max-width: 280px;
  width: 100%;
}
