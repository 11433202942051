@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/breakpoints.scss";

.not-found-page {
  align-content: start;
  display: grid;
  flex-grow: 1;
  gap: 48px;
  justify-items: center;
  padding: calc(var(--vh, 1vh) * 13.6) 16px 16px;

  @include tablet() {
    align-content: center;
    padding: 40px 32px 84px;
  }
}

.not-found-page__container {
  display: grid;
  gap: 40px;
  justify-items: center;
}

.not-found-page__icon {
  color: $color-primary-light-delta;
}

.not-found-page__info {
  display: grid;
  gap: 48px;
  justify-items: center;
  max-width: 400px;
}

.not-found-page__text-wrapper {
  display: grid;
  gap: 16px;
  justify-items: center;
}

.not-found-page__title {
  @include reset-heading();
  @include textH5($weight: $font-weight-bold);
  text-align: center;
}

.not-found-page__subtitle {
  @include reset-description();
  @include textAccent();
  text-align: center;
}

.not-found-page__button {
  width: 200px;
}
