@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

@mixin tabletFooter($direction: "left", $padding: 32px) {
  .footer {
    align-items: center;
    border-top: 0;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: $padding;
  }

  .footer__nav {
    gap: 32px;
    grid-auto-flow: column;
  }

  .footer__links {
    gap: 32px;
    grid-auto-flow: column;

    .footer__link {
      &:nth-of-type(2) {
        grid-column: initial;
        order: initial;
      }

      @if $direction == "left" {
        &:nth-of-type(1) {
          order: initial;
        }
      }

      @if $direction == "right" {
        &:nth-of-type(3) {
          grid-column: initial;
        }
      }
    }
  }

  .footer__link {
    @include textBody1($weight: $font-weight-bold);
    color: $color-theme-primary;
    position: relative;
  }

  .footer__social {
    padding: 0;
  }

  .footer__social-icon {
    height: 40px;
    width: 40px;
  }

  .footer_with-app-links {
    padding-bottom: 48px;
    padding-top: 48px;

    .footer__app-links-wrapper {
      align-items: end;
      grid-row: 1 / 3;
      padding-bottom: 0;
    }

    .footer__links {
      gap: 24px;
      padding-bottom: 0;
    }

    .footer__logo {
      grid-column: 2 / 3;
      grid-row: 2;
      justify-content: end;
    }

    .footer__nav {
      gap: 20px;
      grid-row: 1;
    }
  }
}
