@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/breakpoints.scss";

.statuses-list {
  @include reset-list();
  display: grid;
  gap: 16px;
  justify-content: start;

  @include tablet() {
    gap: 20px;
  }
}

.statuses-list__item {
  @include reset-list-item();
}

.statuses-list__item-label {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: 24px 1fr;
}
