@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/breakpoints.scss";

.list-stub {
  display: grid;
  gap: 32px;
  justify-items: center;
  left: calc(
    (100vw - 220px - 328px) / 2
  ); // minus filter panel minus block width to calc central positioning
  padding-bottom: calc(var(--vh, 1vh) * 10);
  padding-top: calc(var(--vh, 1vh) * 8);
  position: sticky;
  width: 328px;

  @include tablet() {
    gap: 40px;
    padding-top: 0;
  }

  @include desktop() {
    position: static;
    width: auto;
  }
}

.list-stub__icon {
  color: $color-primary-light-delta;
}

.list-stub__info {
  display: grid;
  gap: 16px;
  padding-top: 8px;
  text-align: center;

  @include tablet() {
    padding-bottom: 8px;
  }
}

.list-stub__title {
  @include textH5($weight: $font-weight-bold, $color: $color-black-50);
}

.list-stub__text {
  @include textAccent($weight: $font-weight-regular, $color: $color-black-50);
}

.list-stub__button {
  min-width: 200px;
}
