@import "src/scss/mixins/breakpoints.scss";

.payment-header {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 12px 100px;
  position: relative;

  @include tablet() {
    padding: 32px 200px 12px;
  }
}

.payment-header__toggler {
  position: absolute;
  right: 16px;
  top: 20px;

  @include tablet() {
    right: 32px;
    top: 28px;
  }
}

.payment-header__logo {
  display: block;
  height: 48px;
  width: auto;
}
