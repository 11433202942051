@import "src/scss/mixins/breakpoints.scss";

.table-body_empty {
  align-items: center;
  display: grid;
  height: 100%;

  @include desktop() {
    justify-content: center;
  }
}
